import React from "react";
import { graphql } from "gatsby";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import {
  Jumbotron,
  JumbotronContent,
  JumbotronH1,
  JumbotronH2,
  SeparatorBar,
} from "../components/jumbotron";
import { Main } from "../components/main";
import SEO from "../components/seo";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const [modal, setModal] = React.useState(false);
  return (
    <>
      <SEO
        lang="fr"
        description={frontmatter.description}
        title={frontmatter.title}
        meta={[{ name: "keywords", content: frontmatter.keywords }]}
      />
      <Navbar />
      <div className="container">
        {/* JUMBOTRON */}
        <Jumbotron
          image={
            (frontmatter.banner &&
              frontmatter.banner.childImageSharp.fluid.src) ||
            ""
          }
        >
          <JumbotronContent
            className={"d-flex flex-column justify-content-center"}
          >
            <div className="text-center">
              <JumbotronH1>
                Blog
                <JumbotronH2>{frontmatter.subtitle}</JumbotronH2>
              </JumbotronH1>
              <SeparatorBar />
              <a href="/devis" className="btn-action px-3 py-2">
                Obtenir mon devis gratuit
              </a>
            </div>
          </JumbotronContent>
        </Jumbotron>
        {/* CONTENT */}
        <Main>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <div className="text-center my-5">
            <a href="/devis" className="btn-action px-3 py-2">
              Obtenir mon devis gratuit
            </a>
          </div>
        </Main>
      </div>
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        subtitle
        category
        keywords
        description
        banner {
          childImageSharp {
            fluid(maxWidth: 1100, maxHeight: 400) {
              src
            }
          }
        }
      }
    }
  }
`;
